<template>
  <div id="app">
    <h1>Terraform do Caralho!!!</h1>
    <h2>Version 3</h2>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #7687a5;
  margin-top: 60px;
	
	background-color: #504d4d;
}
</style>
